import React from "react";
import { DefaultButton } from "@fluentui/react";
import { ShadowProps } from "./Shadow";
import { appInsights } from "../AppInsights";

export class NavButtons extends React.PureComponent<ShadowProps> {
    render() {
        const { alias } = this.props;
        return (
            <section style={{ margin: 20 }}>
                <DefaultButton
                    href={`/newtoolmanager`}
                    text="Add new tool manager"
                    iconProps={{ iconName: "add" }}
                    title="add new tool manager"
                    ariaLabel="add new tool manager"
                    styles={{ root: { paddingTop: '6px' }, }}
                    onClick={() => appInsights.trackEvent({ name: 'Clicked on add new tool manager button' }, {
                        callingUser: alias, // who is logged in
                        callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                    })}
                />
            </section>
        );
    }
}
