import React from "react";
import {
    IPersonaProps,
    IBasePicker,
    ValidationState,
    IPickerItemProps,
    PeoplePickerItem,
    IconButton,
    DefaultButton,
    IContextualMenuProps
} from "@fluentui/react";
import {
    IPeoplePickerExampleProps,
    IPeoplePickerExampleState,
    suggestionProps
} from "./IProps";
import Axios from "axios";
import { CompactPeoplePicker, Label, } from '@fluentui/react';
import { TextFieldType } from "../smeHelpers";

type IContactPickerProps = IPeoplePickerExampleProps & IPeoplePickerExampleState & any;

type IContactPickerState = any;

export class ContactPicker extends React.Component<
    IContactPickerProps,
    IContactPickerState
> {
    constructor(props: IContactPickerProps) {
        super(props);
    }

    async componentDidMount() {
        if (this.props.onSetItem === undefined || this.props.value === undefined) return;
        this.props.apiSearchUserByAlias(this.props.value).then((user:any)=>this.props.onSetItem(user))
    }


    private _picker = React.createRef<IBasePicker<IPersonaProps>>();
    public render() {

        const { currentSelectedItems, selectedContactCategory, contactCategories, setState, apiSearchContact, ADD, textField, MULTI } = this.props;

        return (
            <>
                <CompactPeoplePicker
                    
                    selectedItems={currentSelectedItems}
                    onItemSelected={this.onItemSelected(selectedContactCategory, setState, apiSearchContact)}
                    onRenderItem={this.onRenderPeoplePickerItem()}
                    itemLimit={MULTI ? 10 : 1}
                    onResolveSuggestions={this._onFilterChanged}
                    getTextFromItem={this._getTextFromItem}
                    className={"ms-PeoplePicker"}
                    defaultSelectedItems={this.props.currentSelectedItems}
                    key={"list"}
                    pickerSuggestionsProps={suggestionProps}
                    onRemoveSuggestion={this._onRemoveSuggestion}
                    onValidateInput={this._validateInput}
                    inputProps={this.compactPeoplePickerInputProps(textField)}
                    componentRef={this._picker}
                    resolveDelay={300}
                    styles={{
                        root: {
                            display: "inline-block",
                            ...window.location.pathname == "/" && {
                                display: "flex",
                                alignItems: "flex-start"
                            },
                            width: ADD ? "100%" : "70%"
                        }
                    }}
                />
                {textField && textField.displayErrorMessage && (
                    <p className="ms-TextField-errorMessage errorMessage-fieldRequired">
                        <span role="alert" aria-label={textField ? textField.label + 'Field is Required' : "Field is Required"} data-automation-id="error-message">Field is Required</span>
                    </p>
                )}
                {!this.props.groups && (
                    <Label
                        style={{
                            display: "inline-block",
                            width: '40%',
                            backgroundColor: "rgb(0, 120, 212)",
                            color: "rgb(255,255,255)",
                            height: '32px',
                            marginLeft: '10px',
                            paddingTop: '6px'
                        }}
                > General
                        </Label>
                )}
            </>
        );
    }
    private onItemSelected(selectedContactCategory: any, setState: any, apiSearchContact: any): ((selectedItem?: IPersonaProps | undefined) => IPersonaProps | PromiseLike<IPersonaProps> | null) | undefined {
        return (selectedItem?: any | undefined) => {
            const onSelected = this.props.onItemSelected(selectedItem)
            if (onSelected instanceof Promise) {
                onSelected
                    .then((selectedItem: any) => {
                        selectedContactCategory && this.menuOnClick(setState, apiSearchContact)(null, selectedContactCategory);
                        return selectedItem;
                    });

            }
            return onSelected;
        }
    }

    private compactPeoplePickerInputProps(textFields: TextFieldType) {
        return {
            "aria-label": textFields ? textFields.label + " People Picker" :""
        };
    }

    private onRenderPeoplePickerItem(): ((props: IPickerItemProps<IPersonaProps>) => JSX.Element) | undefined {
        return (props: IPickerItemProps<IPersonaProps>) => {
            return (
                <PeoplePickerItem
                    key={props.index}
                    styles={{
                        root: {
                            height: 28,
                            maxWidth: 150
                        }
                    }}
                    item={{
                        ...props.item,
                        ValidationState: ValidationState.valid
                    }}
                    index={props.index}
                    onRemoveItem={()=>this.props.onRemoveItem(props)}
                    removeButtonAriaLabel={'Remove'} />
            );
        };
    }

    private menuProps(contactCategories: any, setState: any, apiSearchContact: any): IContextualMenuProps | undefined {
        return {
            items: contactCategories && contactCategories
                .map((category: any) => {
                    return {
                        key: category.value,
                        text: category.name,
                        iconProps: { iconName: '' },
                        onClick: this.menuOnClick(setState, apiSearchContact)
                    };
                })
        };
    }

    private menuOnClick(setState: any, apiSearchContact: any) {
        type ICategoryType = {
            name: string;
            value: string;
            key: string;
        };

        return (event: any, selectedContactCategory: ICategoryType) => {
            const { key } = selectedContactCategory;
            //@ts-ignore
            setState({
                selectedContactCategory,
                contactList: null,
                legalCategory: key
            });
            apiSearchContact({ type: selectedContactCategory.key });
        };
    }

    private _getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }
    private _onRemoveSuggestion = (item: IPersonaProps): void => {
        const { peopleList, mostRecentlyUsed: mruState } = this.props;
        if (peopleList && mruState) {
            const indexPeopleList: number = peopleList.indexOf(item);
            const indexMostRecentlyUsed: number = mruState.indexOf(item);
            if (indexPeopleList >= 0) {
                const newPeople: IPersonaProps[] = peopleList
                    .slice(0, indexPeopleList)
                    .concat(peopleList.slice(indexPeopleList + 1));
                this.props.setState({ peopleList: newPeople });
            }
            if (indexMostRecentlyUsed >= 0) {
                const newSuggestedPeople: IPersonaProps[] = mruState
                    .slice(0, indexMostRecentlyUsed)
                    .concat(mruState.slice(indexMostRecentlyUsed + 1));
                this.props.setState({ mostRecentlyUsed: newSuggestedPeople });
            }
        }
    };
    private _onFilterChanged = (
        filterText: string
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            const results = this.props.apiSearchUsers(filterText);
            return results;
        }
        return [];
    };
    private _validateInput = (input: string): ValidationState => {
        if (input.indexOf("@") !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    };
}
