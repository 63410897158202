import React from "react";
import {
    TextField,
    Spinner,
    SpinnerSize,
    DefaultButton,
    PrimaryButton,
    Dropdown,
    TagPicker,
    ITag,
    mergeStyles,
    IBasePickerSuggestionsProps,
    MessageBar,
    MessageBarType,

} from "@fluentui/react";
import { ShadowProps } from "./Shadow";
import { FindYourContact } from "./FindYourContact";
import { titleStyles, groupWithShadowsContainerStyles, buttonStyles, groupWithShadowStyles, titleStylesWithTopMargin } from "./styles"
import { appInsights } from "../AppInsights";
import { AxiosError } from "axios";
import { TextFieldType } from "../smeHelpers";



export class Group extends React.PureComponent<ShadowProps> {
    state = {
        submitting: false,
        showErrorMsg: false,
        errorMsg: "Error, please try again."
    }
    constructor(props: any) {
        super(props);
    }

    componentDidMount(){
        // Set work area default
        this.props.setState({
            contactGroupDetails: {
                ...this.props.contactGroupDetails,
                ...this.props.groupDetails
            }
        })
    }

    render() {
        const {
            ADD,
            groupDetails,
            hasGroupDetails,
            textFields,
            title,
            toolmanager,
            viewOnly,
            contactGroupDetails,
            setState,
            post,
            alias
        } = this.props;

        const onChange = (state: {}): Promise<any> => {
            console.log(state)
            return new Promise((resolve) => {
                setState({
                    contactGroupDetails: {
                        ...contactGroupDetails,
                        ...state
                    }
                }, () => resolve(contactGroupDetails))

            })
        };
        return (
            <div style={titleStyles} >
                {this.renderTitle(title)}
                <div style={groupWithShadowsContainerStyles} >
                    {this.renderGroupWithShadow(hasGroupDetails, groupDetails, textFields, ADD, viewOnly, onChange, toolmanager, buttonStyles, post, contactGroupDetails, setState, alias)}
                </div>
            </div>
        );
    }

    private onBlur = (textField: TextFieldType, index: number) => {
        if (!textField.required) return;

        const textFields = { ...this.props.textFields };
        let displayErrorMessage = false;
        displayErrorMessage = (!this.props.contactGroupDetails[textField.key] || this.props.contactGroupDetails[textField.key].toString().trim() === "") ? true : false;
        textFields[index].displayErrorMessage = displayErrorMessage
        this.setState({
            textFields: textFields
        })
    }
    private renderGroupWithShadow(hasGroupDetails: any, groupDetails: any, textFields: TextFieldType[], ADD: any, viewOnly: any, onChange: (state: {}) => Promise<any>, toolmanager: any,
        buttonStyles: { root: { width: string; height: number; color: string; padding: number; margin: number; marginTop: number; }; }, post: any, contactGroupDetails: any, setState: any, alias: string) {

        return <div style={groupWithShadowStyles}>
            {this.renderSpinner(ADD)}
            {this.renderGroupInputs(hasGroupDetails, groupDetails, textFields, ADD, viewOnly, onChange)}
            {this.renderEditModeButton(viewOnly, toolmanager, buttonStyles, post, contactGroupDetails, setState, alias)}
            {this.renderBackButton(toolmanager)}
            {
                this.state.showErrorMsg ?
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => {
                            this.setState({
                                showErrorMsg: false
                            })
                        }}
                        dismissButtonAriaLabel="Close"
                    >
                        {this.state.errorMsg}
                    </MessageBar>
                    : null
            }
        </div>;
    }

    private renderBackButton(toolmanager: any) {
        return <DefaultButton
            href={`/${toolmanager ? 'toolmanagers' : 'contactgroups'}`}
            text="Cancel"
            title="Cancel"
            ariaLabel="Cancel"
            styles={{ root: { height: '25px', padding: '0 14px', margin: '0', lineHeight: '36px', }, }}
        />
    }

    private renderEditModeButton(viewOnly: any, toolmanager: any, buttonStyles: { root: { width: string; height: number; color: string; padding: number; margin: number; marginTop: number; }; }, post: any, contactGroupDetails: any, setState: any, alias: string): React.ReactNode {
        return !viewOnly && (<>
            {this.state.submitting && <Spinner size={SpinnerSize.large} />}
            <PrimaryButton disabled={this.state.submitting} primary styles={buttonStyles} text={this.props.EDIT ? "Update" : "OK"} title={this.props.EDIT ? "EDIT" : "OK"} ariaLabel={this.props.EDIT ? "EDIT" : "OK"} onClick={this.buttonOnClick(post, toolmanager, contactGroupDetails, setState, alias)} />
        </>);
    }

    private isFormValid(): boolean {
        this.props.textFields.map((textField: TextFieldType, index: number) => {
            if (textField.required) {
                this.onBlur(textField, index);
            }
        })

        if (this.props.textFields.some((textField: TextFieldType) => textField.displayErrorMessage)) {
            return false;
        }

        return true;
    }

    private buttonOnClick(post: any, toolmanager: any, contactGroupDetails: any, setState: any, alias: string) {
        return () => {
            if (!this.isFormValid()) return;
            this.state.submitting = true
    
            const data = contactGroupDetails;
            const endpoint = toolmanager ? 'apiToolManagers' : 'apiContactGroups';
    
            post({
                id: contactGroupDetails.assignmentId,
                data,
                endpoint,
                method: this.props.EDIT ? "PUT" : "POST"
            }).then(() => {
                appInsights.trackEvent({ name: `new ${toolmanager ? 'tool manager' : 'contact group'} added` }, {
                    data: data,
                    callingUser: alias, // who is logged in
                    callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                });
                // update this to use react router
                document.location.href = (document.location.pathname === "/newtoolmanager") ? "/toolmanagers" : "/contactgroups";
            })
                .catch((error: AxiosError) => {
                    console.log(error.response);
                    const errorMessage = (error.response?.data as { reasonPhrase?: string })?.reasonPhrase || "Error, please try again.";
                    this.setState({
                        showErrorMsg: true,
                        errorMsg: errorMessage,
                    });
                })
                .finally(() => this.setState({ submitting: false }));
    
            setState({
                contactGroups: undefined,
                groupDetails: undefined,
                toolManagers: undefined
            });
        };
    }
    
    private renderTitle(title: string) {
        return (
            <h1
                style={titleStylesWithTopMargin}>
                {title}
            </h1>
        );
    }

    private renderGroupInputs(hasGroupDetails: any, groupDetails: any, textFields: TextFieldType[], ADD: any, viewOnly: any, onChange: (state: {}) => Promise<any>): React.ReactNode {
        const hasTextFieldsToRender: boolean = !hasGroupDetails || (hasGroupDetails && groupDetails);
        return (
            hasTextFieldsToRender &&
            textFields
                .map(
                    (field: TextFieldType, index: number) => {
                        return [
                            this.newGroup(ADD, field, index, viewOnly, onChange, groupDetails),
                            this.existingGroup(ADD, index, field, viewOnly, onChange, groupDetails)
                        ]
                    }
                )
        )
    }

    private existingGroup(ADD: any, index: number, InputLabel: TextFieldType, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return !ADD && this.getTextFieldExisting(index, InputLabel, viewOnly, onChange, groupDetails);
    }

    private newGroup(ADD: any, textField: TextFieldType, index: number, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return ADD && this.getPeoplePickerFieldsNew(ADD, textField, index, viewOnly, onChange, groupDetails);
    }

    private renderSpinner(ADD: any): React.ReactNode {
        return !ADD && (<Spinner key={"spinner"} style={{ height: 335 }} size={SpinnerSize.large} />);
    }

    private getTextFieldExisting(index: number, InputLabel: TextFieldType, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return (<TextField styles={{
            root: { margin: "auto" }
        }} key={index} label={InputLabel.label} disabled={viewOnly}
            //@ts-ignore
            onChange={(e, v) => onChange(InputLabel.label)(v)} {...viewOnly && {
                placeholder: groupDetails && groupDetails[InputLabel.label]
            }} {...!viewOnly && {
                defaultValue: groupDetails && groupDetails[InputLabel.label]
            }} />);
    }

    private getPeoplePickerFieldsNew(ADD: any, textField: TextFieldType, index: any, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return [
            this.PickerFields(!!textField.isPicker, index, textField, viewOnly, onChange, groupDetails),
            this.dropdownFields(!!textField.isDropDown, index, textField, viewOnly, onChange, groupDetails),
            this.peoplePickerFields(!!textField.isPeoplePicker, index, textField, groupDetails),
            this.textFields(!textField.isPicker && !textField.isPeoplePicker && !textField.isDropDown, index, textField, viewOnly, onChange, groupDetails),
        ];
    }

    private PickerFields(hasPickerFields: boolean, i: any, textField: TextFieldType, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        const rootClass = mergeStyles({
            maxWidth: 500,
        });

        const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: `Suggested  ${textField.label}`,
            noResultsFoundText: `No ${textField.label} found`,
        };

        const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
            if (!tagList || !tagList.length || tagList.length === 0) {
                return false;
            }
            return tagList.some(compareTag => compareTag.key === tag.key);
        };

        const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
            return filterText.trim().length !== 0
                ? this.props[textField.propKey || 0].filter(
                    (tag: any) => tag.name.toLowerCase().indexOf(filterText.trim().toLowerCase()) >= 0 && !listContainsTagList(tag, tagList),
                )
                : this.props[textField.propKey || 0];
        };

        const getTextFromItem = (item: ITag) => {
            return item.name;
        };

        const pickerId = `picker-${textField.key}`

        return hasPickerFields && (
            <div key={i} style={{ textAlign: "left", height: "82px" }}>
                <label htmlFor={pickerId} style={{ display: "block", padding: "5.5px 0" }}>{textField.label} <span className="errorMessage-requiredAsterisk">{textField.required ? " *" : ""}</span></label>
                <TagPicker
                    key={i}
                    removeButtonAriaLabel="Remove"
                    onResolveSuggestions={filterSuggestedTags}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    defaultSelectedItems={((this.props.EDIT || textField.displayDefault) && groupDetails[textField.key] !== null) ? [{ key: groupDetails[textField.key], name: groupDetails[textField.secondKey || 0] || groupDetails[textField.key] }] : undefined}
                    itemLimit={1}
                    disabled={textField.disable}
                    onBlur={() => this.onBlur(textField, i)}
                    onChange={(e: ITag[] | undefined) => {
                        if (!e || e.length < 1) {
                            onChange({ [textField.key]: null, [textField.secondKey || 0]: null });
                            return;
                        };
                        onChange({ [textField.key]: e[0].key, [textField.secondKey || 0]: e[0].name });
                    }}
                    inputProps={{
                        id: pickerId,
                    }}
                />
                {textField && textField.displayErrorMessage && (
                    <p className="ms-TextField-errorMessage errorMessage-fieldRequired">
                        <span data-automation-id="error-message">Field is Required</span>
                    </p>
                )}
            </div>);
    }

    private dropdownFields(hasDropdownFields: boolean, i: any, textField: TextFieldType, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return hasDropdownFields && (
            <Dropdown
                key={i}
                label={textField.label}
                options={this.props[textField.propKey || 0]}
                styles={{
                    root: { textAlign: "left", height: "82px" }
                }}
                required={textField.required}
                aria-required={textField.required}
                errorMessage={textField.displayErrorMessage ? "Field is Required" : undefined}
                disabled={viewOnly}
                defaultSelectedKey={(this.props.EDIT) ? groupDetails[textField.key] : undefined}
                onBlur={() => this.onBlur(textField, i)}
                onChange={(e : any, v :any) => onChange({ [textField.key]: v.key })}
            />);
    }

    private textFields(hasTextFields: boolean, i: any, textField: TextFieldType, viewOnly: any, onChange: (state: {}) => Promise<any>, groupDetails: any) {
        return hasTextFields && (
            <TextField
                key={i}
                styles={{
                    root: { textAlign: "left", height: "82px" }
                }}
                label={textField.label}
                required={textField.required}
                aria-required={textField.required}
                errorMessage={textField.displayErrorMessage ? "Field is Required" : undefined}
                disabled={viewOnly}
                defaultValue={(this.props.EDIT) ? groupDetails[textField.key] : undefined}
                //@ts-ignore
                onBlur={() => this.onBlur(textField, i)}
                onChange={(e, v) => onChange({ [textField.key]: v })}
            />);
    }
    

    private peoplePickerFields(hasPeoplePickerFields: boolean, i: any, textField: TextFieldType, groupDetails: any) {
        const pickerId = `picker-${textField.key}`

        return hasPeoplePickerFields && (
            <div key={i} style={{ textAlign: "left", height: "82px" }}>
                <label htmlFor={pickerId} style={{ display: "block", padding: "5.5px 0" }}>{textField.label} <span className="errorMessage-requiredAsterisk">{textField.required ? " *" : ""}</span></label>
                <FindYourContact
                    {...this.props}
                    apiSearchUsers={this.props.apiSearchUsers}
                    apiSearchContactsLegal={this.props.apiSearchContactsLegal}
                    groups
                    title={textField.label}
                    textField={textField}
                    MULTI={textField.isMultiplePeoplePicker}
                    value={(this.props.EDIT) ? groupDetails[textField.key] : undefined}
                    onSetItem={(item:any) => {
                        this.props.setState({
                            currentSelectedItems: {
                                ...this.props.currentSelectedItems,
                                [textField.key]: item
                            },
                            contactGroupDetails: {
                                ...this.props.contactGroupDetails,
                                [textField.key]: (item[0]) ? item[0].mail : null
                            }
                        });
                    }}
                    currentSelectedItems={this.props.currentSelectedItems && this.props.currentSelectedItems[textField.key]}
                    onRemoveItem={(({ name, isMulti }: any) => (selectedItem?: any | undefined) => {
                        const currentSelectedItemsIndex: number = this.props.currentSelectedItems[name].indexOf(selectedItem.item);
                        this.props.currentSelectedItems[name].splice(currentSelectedItemsIndex, 1)

                        const contactGroupDetailsIndex: number = this.props.contactGroupDetails[name].indexOf(selectedItem.item.mail);
                        if (isMulti) this.props.contactGroupDetails[name].splice(contactGroupDetailsIndex, 1)

                        selectedItem &&
                            this.props.setState({
                                currentSelectedItems: {
                                    ...this.props.currentSelectedItems,
                                    [name]: this.props.currentSelectedItems[name]
                                },
                                contactGroupDetails: {
                                    ...this.props.contactGroupDetails,
                                    [name]: (isMulti) ? this.props.contactGroupDetails[name] : null
                                }
                            });
                        return selectedItem ? selectedItem : null;
                    })({ name: textField.key, isMulti: textField.isMultiplePeoplePicker })}
                    onItemSelected={(({ name }: any) => (selectedItem?: any | undefined) => {
                        selectedItem &&
                            this.props.setState({
                                currentSelectedItems: {
                                    ...this.props.currentSelectedItems,
                                    [name]: (Array.isArray(this.props.currentSelectedItems[name])) ? [...this.props.currentSelectedItems[name], ...[selectedItem]] : [selectedItem]
                                },
                                contactGroupDetails: {
                                    ...this.props.contactGroupDetails,
                                    [name]: (Array.isArray(this.props.contactGroupDetails[name])) ? [...this.props.contactGroupDetails[name], ...[selectedItem.mail]] : (textField.isMultiplePeoplePicker) ? [selectedItem.mail] : selectedItem.mail
                                }
                            });
                        return selectedItem ? selectedItem : null;
                    })({ name: textField.key })} />
            </div>);
    }
}
