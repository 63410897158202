import React from "react";
import { PrimaryButton, PersonaCoin } from '@fluentui/react';
import { PersonProps } from "./IProps";
import { appInsights } from "../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export class PersonaBadge extends React.Component<PersonProps> {
    public render(): JSX.Element {
        const { person, user, alias } = this.props;
        return (<div >
            <PersonaCoin
                showUnknownPersonaCoin={person && !person.imageUrl}
                imageUrl={person && person.imageUrl}
                coinSize={200}
                style={{
                    width: 200,
                    margin: 'auto'
                }}
            />
            <p style={{ fontSize: 20, fontWeight: 500, lineHeight: 1.2, fontFamily: 'arial', }} aria-label={`${person ? person.text : null}`}>{person && person.text}</p>
            <p style={{ color: '#666', fontSize: 13, fontFamily: 'arial' }}>{person && person.secondaryText}</p>
            {(person as any).mail ?
                <PrimaryButton href={`mailto:${(person as any).mail}`} title={'Send E-Mail'} text="Contact" data-automation-id="test" allowDisabledFocus={true} style={{ width: '100%', marginTop: 10, marginBottom: 16, alignItems: 'center', paddingTop: '6px' }} onClick={() => appInsights.trackEvent({ name: 'Clicked on Contact CELA Contact' }, {
                    celaContactEmail: (person as any).mail, // who they contacted
                    callingUser: alias, // who is logged in
                    lookupAlias: user, // who they looked up
                    callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                })} />
                : <PrimaryButton href="mailto:askcela@microsoft.com" title={'Email Ask Cela'} text="Contact askCELA" data-automation-id="test" allowDisabledFocus={true} style={{ width: '100%', marginTop: 10, marginBottom: 16 }} onClick={() => appInsights.trackEvent({ name: 'No Contact Found,Ask Cela' }, {
                    celaContactEmail: (person as any).mail, // who they contacted
                    callingUser: alias, // who is logged in
                    lookupAlias: user, // who they looked up
                    callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                })} />}
            <div style={{ display:'none'}} aria-hidden="false" aria-label="Loading complete" > Loading complete</div>
        </div>);
    }

    OpenFeedback() {
        const { person, user, alias } = this.props;
        (window as any).OfficeBrowserFeedback.singleFeedback("Bug", { telemetryGroup: { featureArea: "User" } }).then(
            function onFulfilled() {
                appInsights.trackEvent({ name: 'Clicked on feedback (Contact Not Found)' },{
                    celaContactEmail: (person as any).mail, // who they contacted
                    callingUser: alias, // who is logged in
                    lookupAlias: user, // who they looked up
                    callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                });
            }
        )
            .catch(
                function onRejected(err: any) {
                    appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error });
                }
            );
    }
}
