import React from "react";
import { TextFieldType } from "../smeHelpers";
import { Group } from "./Group";

const textFields: TextFieldType[] = [
    { label: "Work Area", key: "workAreaId", secondKey: "workAreaName", required: true, isPicker: true, propKey: "workAreas",displayDefault:true },
    { label: "Leader", key: "leadsAlias", required: false, isPeoplePicker: true },
    { label: "Tool Manager(s)", key: "toolManagerAlias", required: true, isPeoplePicker: true },
];

export class AddToolManagers extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        document.title = "Add Tool Manager";
        this.state = {
            workAreas: []
        }
        const { isToolManager, setState, apiGetWorkAreas } = this.props;
        isToolManager()
            .then((res: any) => { setState({ isToolManagerBool: res }); });
        apiGetWorkAreas().then((workAreas: any) => {
            workAreas.map((area: any) => {
                this.state.workAreas.push({
                    key: area.id,
                    name: area.name
                })
            })
        })
    }
    render() {
        return (
            <>
                <Group
                    {...this.props}
                    title={"New Tool Manager"}
                    textFields={textFields}
                    workAreas={this.state.workAreas}
                    groupDetails={{workAreaId: process.env.REACT_APP_defaultWorkAreaID, workAreaName: process.env.REACT_APP_defaultWorkAreaName}}
                    toolmanager
                    CONTACT
                    ADD
                />
            </>
        );
    }
}
